import * as React from 'react';
import { styled, useTheme  } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import pic from '../doctor_care_logo.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button , Stack, Typography, useMediaQuery} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductsCartDetails from './ProductsCartDetails';
import CloseIcon from '@mui/icons-material/Close';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));
export default function Cart({opening,setOpening,details}) {
  const [total,setTotal] =React.useState('')
  const[addressid,setaddressid] = React.useState('')
  const lang = localStorage.getItem('lang')

  React.useEffect(()=>{
    const previousPath = localStorage.getItem('previousPath');
    if (previousPath === 'AddAddress') {
      setOpening(true)
    }
    if(details.product.price_after_discount !== 0){
      setTotal(details.product.price_after_discount)
    }
    if(details.product.price_after_discount === 0){
      setTotal(details.product.price)
    }
  },[])
  const theme = useTheme();
  const handleDrawerClose = () => {
    setOpening(false);
  };
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:1000px)')
  const mm = useMediaQuery('(min-width:600px)')
  const [count,setCount] = React.useState(0)
  const handleAdd = () => {
    setCount(count + 1);
    if (details.product.price_after_discount) {
      setTotal(details.product.price_after_discount * (count + 1));
    } else {
      setTotal(details.product.price * (count + 1));
    }
  };
  
  const handleSubtract = () => {
    if (count === 0) {
      setCount(0);
    } else {
      setCount(count - 1);
      if (details.product.price_after_discount) {
        setTotal(details.product.price_after_discount * (count - 1));
      } else {
        setTotal(details.product.price * (count - 1));
      }
    }
  };
  const fallbackImageUrl = pic;

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: mm?'40%':'100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: mm?'40%':'100%',
          },position:'relative'
        }}
        anchor={lang === 'en' ?"right" : 'left'}
        open={opening}
      >
        {/* <DrawerHeader> */}
          <IconButton onClick={handleDrawerClose} sx={{ '&:hover':{
            bgcolor:'transparent'
          }, position:'absolute',left:'10px' }}>
             <ChevronRightIcon sx={{color:'white',bgcolor:'#0a97d1',
             borderRadius:'100px','&:hover':{
              color:'white',bgcolor:'#0a97d1'
            }}}/>
          </IconButton>
        {/* </DrawerHeader> */}
       {/* <IconButton onClick={handleDrawerClose} sx={{ '&:hover':{
            bgcolor:'transparent'
          }, position:'absolute',left:'10px' }}>
             <CloseIcon sx={{color:'white',color:'#0a97d1',borderRadius:'100px','&:hover':{
            bgcolor:'transparent'
          }}}/>
          </IconButton> */}
        <Stack sx={{padding:'20px',bgcolor:'#E1DDE0B3'}}>
           <Stack direction={matches?'row':'column'} gap={2}>
      <img src={`${process.env.REACT_APP_API_URL_IMAGE_PRODUCTS}${details.product.images[0]}`} onError={(e) => {
        e.target.src = fallbackImageUrl
      }} alt='...loading' style={{width:'70px',height:'70px',objectFit:'fill',marginTop:'1.5rem'}}/>
            <Stack  gap={1}  sx={{display:'flex',flexWrap:'wrap',padding:'20px'}}>
            <Typography sx={{ color:'gray' }}>
          {details.product.title}
        </Typography>        
       {/* <Typography sx={{ fontWeight:'bold' }}>  {t('Description')} : </Typography> */}
         <Typography>
          {details.product.description}
        </Typography>
       {/* <Typography sx={{ fontSize:'16px',fontWeight:'bold' }}>  {t('Price')} : </Typography> */}
       <Stack direction={matches?'row':'column'} gap={1}>
       {details.product.price_after_discount &&<>
                      <Typography sx={{ textDecoration: 'line-through 1px' , color:'#0a97d1'}}>
                 {details.product.price} SAR
              </Typography>
              <Typography sx={{color:'#0a97d1'}}>
                {details.product.price_after_discount} SAR
              </Typography> </>}
              {!details.product.price_after_discount &&<>
              <Typography sx={{color:'#0a97d1'}}>
                {details.product.price} SAR
              </Typography> </>}
              </Stack>
        </Stack>
        <Stack direction={matches?'row':'column'} gap={2} sx={{ 
           marginTop:'50px'}}>
          <Button sx={{ backgroundColor:"#0a97d1",height:'20px',width:'2rem','&:hover':{
            bgcolor:"#0a97d1"
          } }} onClick={handleAdd}>
          <AddIcon sx={{color:'white' }}/>
          </Button>
          <Typography sx={{ fontSize:'16px',fontWeight:'bold' }}>
          {count}
          </Typography>
          <Button sx={{ backgroundColor:"#0a97d1",height:'20px',width:'2rem','&:hover':{
            bgcolor:"#0a97d1"
          } }} onClick={handleSubtract}>
          <RemoveIcon sx={{color:'white' }}/>
          </Button>
          </Stack>
        </Stack>
      </Stack>
      <ProductsCartDetails count={count}
       details={details} total={total} addressid={addressid} setaddressid={setaddressid} />
      </Drawer>
    </Box>
  );
}
