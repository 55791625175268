import { Stack, Typography, Grid, TextField, Button, Alert, Box } from '@mui/material'
import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import ProductAddressCards from './ProductAddressCards';
import axios from 'axios';
import { useNavigate } from 'react-router';

export default function ProductsCartDetails({details,total,addressid,setaddressid,count}) {
  const[code,setCode] = useState('')
  const [taxes,setTaxes] = useState(false)
  const [finalTotal,setFinalTotal]=useState('')
  const [alerting,setAlerting] = useState(false)
  const [finaldiscount,setFinalDiscount] = useState('')
  const [cart_id,setCartId] = useState('')
  const { t } = useTranslation();
  const token = localStorage.getItem('token')
  const [al,setAl] =useState(false)
  const navigate = useNavigate()
  const [error, setError] = React.useState(null);
  const [handling,setHandling] =useState(false)
  React.useEffect(()=>{
    setHandling(false)
    axios.get(`${process.env.REACT_APP_API_URL}address/all_address`,{headers :{
      Authorization: `Bearer ${token}`
    }}).then(res=>{
      if(res.data.data.user_country === 'SA'){
        setTaxes(true)
      }
    })
    if(details.product.price_after_discount !== 0){
      if(!taxes){
        setFinalTotal(details.product.price_after_discount + (details.product.price_after_discount *(16/100) - 0))
      }
      else{
        setFinalTotal(details.product.price_after_discount - 0)
      }
    }
    if(details.product.price  && details.product.price_after_discount === 0){
      if(!taxes){
        setFinalTotal(details.product.price + (details.product.price *(16/100) - 0))
      }
      else{
        setFinalTotal(details.product.price - 0)
      }
    }
},[taxes])


const handleCode = () => {
const data = {
  product_ids: [details.product.id],
  qty: [count],
  action: "add"
};
  axios.post(`${process.env.REACT_APP_API_URL}add-to-cart`,data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(res=>{
          setCartId(res.data.data.cart.id)
          setHandling(true)
        })
  if (code) {
    const formData = new FormData();
    formData.append('code', code);
    formData.append('cart_id', cart_id);
    try {
      axios.post(`${process.env.REACT_APP_API_URL}check_copoun`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if(res.data.data.type==='number' && res.data.data.value > 0){
        if(details.product.price && details.product.price_after_discount === 0){
          if(!taxes){
            setFinalTotal(details.product.price + (details.product.price *(16/100)) - (details.product.price - res.data.data.value))
          }
          else{
            setFinalTotal(details.product.price - (details.product.price - res.data.data.value))
          }
        setFinalDiscount(details.product.price - res.data.data.value)}
      if(details.product.price_after_discount !== 0){
        if(!taxes){
          setFinalTotal(details.product.price_after_discount + (details.product.price_after_discount *(16/100)) - (details.product.price_after_discount - res.data.data.value))
        }
        else{
          setFinalTotal(details.product.price_after_discount - (details.product.price_after_discount - res.data.data.value))
        }
        setFinalDiscount(details.product.price_after_discount - res.data.data.value)}
      }
        if(res.data.data.type==='percentage'){
          if(details.product.price && details.product.price_after_discount === 0){
            if(!taxes){
              setFinalTotal(details.product.price + (details.product.price *(16/100)) - (details.product.price * (res.data.data.value/100)))
            }
            else{
              setFinalTotal(details.product.price - (details.product.price * (res.data.data.value/ 100)))
            }
            setFinalDiscount(details.product.price * (res.data.data.value/100))
          }
          if(details.product.price_after_discount !== 0){
            if(!taxes){
              setFinalTotal(details.product.price_after_discount + (details.product.price_after_discount *(16/100)) - (details.product.price_after_discount *(res.data.data.value/ 100)))
            }
            else{
              setFinalTotal(details.product.price_after_discount - (details.product.price_after_discount*(res.data.data.value/ 100)))
            }
            setFinalDiscount(details.product.price_after_discount * (res.data.data.value/ 100))
          }
        }
      })
      .catch(error => {
        if (error.response.data.message === 'tr.Coupon not found or not valid for this date') {
          setAlerting(true);
        } 
      });
    } catch (error) {
      setAlerting(true);
    }
  }
}

const handleProceed =() =>{
  const formData = new FormData();
  const data = {
    product_ids: [details.product.id],
    qty: [count],
    action: "add"
  };
  if(addressid){
    if(!handling){
    axios.post(`${process.env.REACT_APP_API_URL}add-to-cart`, data, {
      headers: {
        Authorization:  `Bearer ${token}`,
      },
    })
    .then(res => {
      const params = {
        address_id: addressid,
        cart_id: res.data.data.cart.id,
      };
      return axios.post(`${process.env.REACT_APP_API_URL}add-order-product`,params, {
        headers: {
          Authorization:  `Bearer ${token}`,
        },
      });
    })
    .then(res => {
      navigate('/DashBoardProduct');
    })
    .catch((error) => {
      setError(error.response.data.message);
    });
  }

  if(handling){
  axios.post(`${process.env.REACT_APP_API_URL}add-order-product?address_id=${addressid}&cart_id=${cart_id}`, formData,{
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})
.then((res) => {
  navigate('/DashBoardProduct')
}).catch((error) => {
  setError(error.response.data.message);
  setAl(false)
});
}
}
else{
  setAl(true)
} 
}
  return (
    <div style={{marginTop:'10px'}}>
      <Box sx={{padding:'20px'}}>
       <Typography sx={{fontSize:'15px',color:'#0a97d1'}}>
            {t('Promo Code')}
        </Typography>
        <Grid>
            <TextField
              value={code}
              onChange={(e) => { setCode(e.target.value);setAlerting(false) }}
              sx={{ width: '250px', border: '1px solid white',marginTop:'10px'
              ,'.MuiInputBase-root':{      
                borderRadius:'100px',border:'1px solid #0a97d1',height:'35px'
         },'& .MuiInputLabel-root': {
          marginTop:'-9px'
       }}}
            />
            <Button sx={{ color: 'white', padding: '10px', bgcolor: '#0a97d1', 
            marginTop: '10px',height:'35px',marginLeft:'10px'
        ,borderRadius:'100px','&:hover ':{bgcolor: '#0a97d1',color:'white'} }}
        onClick={handleCode}>{t('Apply')}</Button>
          </Grid>
          {alerting && <Alert severity='error' sx={{  width: '350px',marginTop:'10px' }}
         >{t('Coupon not found or not valid for this date')}</Alert>}
         </Box>

            <ProductAddressCards addressid={addressid} setaddressid={setaddressid}/>
        <Stack sx={{padding:'20px'}}>
        <Stack direction='row' gap={1}>
                     {details.product.price_after_discount &&<>
                      <Typography sx={{ textDecoration: 'line-through 1px',fontSize:'15px' }}>
                 {details.product.price} SAR
              </Typography>
              <Typography sx={{fontSize:'15px'}}>
                {details.product.price_after_discount} SAR
              </Typography> </>}
              {!details.product.price_after_discount &&<>
              <Typography sx={{fontSize:'15px'}}>
                {details.product.price} SAR
              </Typography> </>}
            </Stack>
            <Stack direction='row' gap={1}>
            <Typography sx={{fontSize:'15px',color: '#0a97d1'}}>
               {t('Subtotal')} :
              </Typography> 
              <Typography sx={{fontSize:'15px'}}>
               {total} SAR
              </Typography>
              </Stack> 

              <Stack direction='row' gap={1}>
              <Typography sx={{fontSize:'15px',color: '#0a97d1'}}>
               {t('Tax')} :
              </Typography>
              {taxes ? <>
              <Typography sx={{fontSize:'15px'}}>
               0 SAR
              </Typography> </>: 
               <Typography sx={{fontSize:'15px'}}>
               {details.product.price_after_discount !== 0? details.product.price_after_discount * (16 / 100) : 
               details.product.price && details.product.price_after_discount === 0 ? details.product.price * (16 / 100) : null } SAR
             </Typography>}
              </Stack> 

              <Stack direction='row' gap={1}>
            <Typography sx={{fontSize:'15px',color: '#0a97d1'}}>
               {t('Discount')} :
              </Typography> 
              <Typography sx={{fontSize:'15px'}}>
              {!code ? 0 : finaldiscount } SAR
              </Typography>
              </Stack> 


              <Stack direction='row' gap={1}>
            <Typography sx={{fontSize:'15px',color: '#0a97d1'}}>
               {t('Total')} :
              </Typography> 
              <Typography sx={{fontSize:'15px'}}>
              {finalTotal} SAR
              </Typography>
              </Stack> 
              <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignContent:'center',marginBottom:'30px'}}>
              {al && <Alert severity="error" sx={{marginTop:'20px',width:'150px'}}>{t('You Must Choose Address')}</Alert>}
              </Box>
    <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignContent:'center',marginBottom:'30px'}}>
      <Button onClick={handleProceed}
      sx={{ color: 'white', padding: '10px', bgcolor: '#0a97d1', marginTop: '10px', width: '180px'
        ,borderRadius:'100px','&:hover ':{bgcolor: '#0a97d1',color:'white'} }}>{t('Proceed')}</Button>
        </Box>
        </Stack>
    </div>
  )
}
