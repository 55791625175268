import { Container, Stack, Typography, Grid , TextField, Button, Alert } from '@mui/material';
import React, { useState,useRef  } from 'react'
import { useLocation, useNavigate } from 'react-router';
import pic from '../doctor_care_logo.png';
import AdressesCards from '../Components/AdressesCards';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function Checkout({setNav}) {
    const[code,setCode] = useState('')
    const location = useLocation();
    const [taxes,setTaxes] =useState(false)
    const [finalTotal,setFinalTotal]=useState('')
    const [numdiscount,setNumDiscount]=useState('')
    const [percentagediscount,setPercentageDiscount]=useState(10)
    const [finaldiscount,setFinalDiscount] = useState('')
    const [alerting,setAlerting] = useState(false)
    const[addressid,setaddressid] = useState('')
    const [error, setError] = React.useState(null);
    const [al,setAl] =useState(false)
    const { t } = useTranslation();

    const { state } = location;
    const { arr, details, price , total, p } = state;
    const token = localStorage.getItem('token')
    const navigate = useNavigate()

    React.useEffect(() => {
      const previousPath = localStorage.getItem('previousPath');
      if (previousPath !== 'ServiceDetails' && previousPath !== 'AddAddress') {
        navigate('/');
      }
    }, []);

    React.useEffect(()=>{
      setNav(true)
      window.scrollTo(0,0)
      axios.get(`${process.env.REACT_APP_API_URL}address/all_address`,{headers :{
        Authorization: `Bearer ${token}`
      }}).then(res=>{
        if(res.data.data.user_country === 'SA'){
          setTaxes(true)
        }
        console.log( res.data.data.user_country)
      })
      if(total !== 'based on period' && total !== 0){
        if(!taxes){
          setFinalTotal(total + (total *(16/100) - 0))
        }
        else{
          setFinalTotal(total - 0)
        }
      }
      if(price !== 'based on period' && total === 0){
        if(!taxes){
          setFinalTotal(price + (price *(16/100) - 0))
        }
        else{
          setFinalTotal(price - 0)
        }
      }
    if(p !== 0){
      if(!taxes){
        setFinalTotal(p + (p *(16/100) - 0))
      }
      else{
        setFinalTotal(p)
      }
    }
  },[taxes])
  const windowHeight = useRef(window.innerHeight);
  const handleCode = () => {
      if (code) {
        const formData = new FormData();
        formData.append('code', code);
        formData.append('service_id', details.services.id);
        try {
          axios.post(`${process.env.REACT_APP_API_URL}check_copoun`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(res => {
            window.scrollTo(0,windowHeight.current);
            if(res.data.data.type==='number' && res.data.data.value > 0){
              // setNumDiscount(res.data.data.value)
            if(price !== 'based on period'){
              if(!taxes){
                setFinalTotal(price + (price *(16/100)) - (price - res.data.data.value))
              }
              else{
                setFinalTotal(price - (price - res.data.data.value))
              }
            setFinalDiscount(price - res.data.data.value)}
          if(total !== 'based on period'){
            if(!taxes){
              setFinalTotal(total + (total *(16/100)) - (total - res.data.data.value))
            }
            else{
              setFinalTotal(total - (total - res.data.data.value))
            }
            setFinalDiscount(total - res.data.data.value)}
          if(p !== 0){
            if(!taxes){
              setFinalTotal(p + (p *(16/100)) - (p - res.data.data.value))
            }
            else{
              setFinalTotal(p - (p - res.data.data.value))
            }
            setFinalDiscount(p - res.data.data.value)}
          }
            if(res.data.data.type==='percentage'){
              if(price !=='based on period'){
                if(!taxes){
                  setFinalTotal(price + (price *(16/100)) - (price * (res.data.data.value/100)))
                }
                else{
                  setFinalTotal(price - (price * (res.data.data.value/ 100)))
                }
                setFinalDiscount(price * (res.data.data.value/100))
              }
              if(total !== 'based on period'){
                if(!taxes){
                  setFinalTotal(total + (total *(16/100)) - (total *(res.data.data.value/ 100)))
                }
                else{
                  setFinalTotal(total - (total*(res.data.data.value/ 100)))
                }
                setFinalDiscount(total * (res.data.data.value/ 100))
              }
              if(p !== 0){
                if(!taxes){
                  setFinalTotal(p + (p *(16/100)) - (p*(res.data.data.value)/ 100))
                }
                else{
                  setFinalTotal(p* (res.data.data.value/ 100))
                }
                setFinalDiscount(p *(res.data.data.value / 100))}
            }
          })
          .catch(error => {
            if (error.response.data.message === 'tr.Coupon not found or not valid for this date') {
              setAlerting(true);
            } 
          });
        } catch (error) {
          setAlerting(true);
        }
      }
    };
    const fallbackImageUrl = pic;

    const handleProceed =() =>{
      const formData = new FormData();
      formData.append('description', arr[0].description);
      if(addressid){
      if(arr[0].forMe === 1){
      formData.append('for_other', 0)}
      if(arr[0].forMe === 0){
        formData.append('for_other', 1);
        formData.append('patient_name',arr[0].pname)
        formData.append('patient_gender',arr[0].pGender)
        formData.append('patient_age',arr[0].page)
      }
      else{
        formData.append('for_other', 0);
        formData.append('patient_name','')
        formData.append('patient_gender','')
        formData.append('patient_age','')
      }
      formData.append('address_id', addressid);
      formData.append('amount',finalTotal);
      if(finaldiscount){
      formData.append('discount', finaldiscount);}
      if(!finaldiscount){
        formData.append('discount', 0);
      }
          axios.post(`${process.env.REACT_APP_API_URL}${details.services.id}/order_service`, formData,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      navigate('/DashBoardService')
    }).catch((error) => {
      setError(error.response.data.message);
      setAl(false)
    });
    }
    else{
      setAl(true)
    } 
    }
    return (
    <div>
        <Container>
      <Stack sx={{padding:'20px'}}>
        <Typography sx={{fontSize:'25px',color:'#0a97d1',fontWeight:'bold'}}>
            {t('Service Details')}
        </Typography>
        <Stack direction='row' sx={{marginTop:'20px'}} gap={3}>
            <img src={`${process.env.REACT_APP_API_URL_IMAGE_SERVICE}${details.services.images[0]}`} 
         onError={(e) => {
          e.target.src = fallbackImageUrl
        }} alt='...loading' style={{width:'100px',height:'100px',objectFit:'contain',borderRadius:'20px'}}/>
            <Stack>
                {arr.map((arr)=>
                <Typography sx={{color:'gray',fontSize:'16px'}}>
                    {t('Description')} : {arr.description} <br />
                   {arr.period && <> {t('Period')} : {arr.period} <br /></>}
                   {arr.startDate && <>  {t('Start Date')} : {arr.startDate}<bt/></>}
                   {total && total !== 'based on period' ?    
                     <Stack direction='row' gap={1}>
                  <Typography sx={{ fontSize: '16px' }}>
                  {t('total')} : 
                    </Typography>
                      <Typography sx={{ textDecoration: 'line-through 1px', fontSize: '16px' }}>
                 {price} SAR
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                {total} SAR
              </Typography> 
            </Stack>: 
            <>
            {price !== 'based on period' ?  
          <Typography sx={{ fontSize: '16px' }}>
            {t('total')} :{price} SAR
         </Typography>:  <Typography sx={{ fontSize: '16px' }}>
         {t('price')} : {p} SAR
       </Typography>}
            </>}
                    {arr.forMe === 1 ? <Typography sx={{color:'gray',fontSize:'16px'}}>
                        {t('For My Own')}
                        </Typography> : <Typography sx={{color:'gray',fontSize:'16px'}}>
                        {t('Patient Name')} : {arr.pname} <br />
                        {t('Patient Age')} : {arr.page} <br />
                        {t('Patient Gender')} : {arr.pGender} 
                        </Typography>}
                </Typography>
                )}
            </Stack>
        </Stack>
        <Typography sx={{fontSize:'25px',color:'#0a97d1',fontWeight:'bold',marginTop:'60px'}}>
            {t('Promo Code')}
        </Typography>
        <Grid>
            <TextField
              label='PromoCode'
              value={code}
              onChange={(e) => { setCode(e.target.value);setAlerting(false) }}
              sx={{ width: '300px', border: '1px solid white',marginTop:'20px','.MuiInputBase-root':{      
                borderRadius:'100px',border:'1px solid #0a97d1'
         }}}
            />
            <Button sx={{ color: 'white', padding: '10px', bgcolor: '#0a97d1', marginTop: '25px',marginLeft:'20px'
        ,borderRadius:'100px','&:hover ':{bgcolor: '#0a97d1',color:'white'} }}
        onClick={handleCode}>{t('Apply')}</Button>
          </Grid>
         {alerting && <Alert severity='error' sx={{  width: '350px',marginTop:'10px' }}
         >{t('Coupon not found or not valid for this date')}</Alert>}
          <Typography sx={{fontSize:'25px',color:'#0a97d1',fontWeight:'bold',marginTop:'60px'}}>
            {t('Addresses')}
        </Typography>
        <AdressesCards addressid={addressid} setaddressid={setaddressid}/>

        <Typography sx={{fontSize:'25px',color:'#0a97d1',fontWeight:'bold',marginTop:'60px'}}>
            {t('Payment Details')}
        </Typography>
        <Stack>
        <Stack direction='row' gap={5} sx={{marginTop:'20px'}}>
        <Typography sx={{fontSize:'16px'}}>
            {t('SubTotal')}
         </Typography>         
         <Typography sx={{ fontSize: '16px' }}>
  {price !== 'based on period' && total === 0 ? price : total !== 'based on period' ? total : p } SAR
      </Typography>
        </Stack>

        <Stack direction='row' gap={9.6} sx={{marginTop:'5px'}}>
        <Typography sx={{fontSize:'16px'}}>
            {t('Tax')}
         </Typography>        
         {taxes ?
         <Typography sx={{fontSize:'16px'}}>
            0 SAR
         </Typography> :
          <Typography sx={{fontSize:'16px'}}>
            {total !== 'based on period' && total !== 0? total * (16 / 100) : 
            price !== 'based on period' && total === 0 ? price * (16 / 100) : p * (16 / 100)} SAR
          </Typography>}
        </Stack>
        <Stack direction='row' gap={5} sx={{marginTop:'5px'}}>
        <Typography sx={{fontSize:'16px'}}>
            {t('Discount')}
         </Typography>         
         <Typography sx={{fontSize:'16px'}}>
            {!code ? 0 : finaldiscount } SAR
         </Typography>
        </Stack>

        <Stack direction='row' gap={7.6} sx={{marginTop:'20px'}}>
        <Typography sx={{fontSize:'18px',fontWeight:'bold'}}>
           {t('Total')}
         </Typography>         
         <Typography sx={{fontSize:'18px',fontWeight:'bold'}}>
         {/* {p ? p + (p * (16 / 100)) - finaldiscount :
            total ? total + (total * (16 / 100)) - finaldiscount : price + (price * (16 / 100)) - finaldiscount } */}
            {finalTotal} SAR
         </Typography>
        </Stack>
        </Stack>
        {error && <Alert severity="error" sx={{marginTop:'20px',width:'250px'}}>{error}</Alert>}
        {al && <Alert severity="error" sx={{marginTop:'20px',width:'250px'}}>{t('You Must Choose Address')}</Alert>}
        <Button onClick={handleProceed}
        sx={{ color: 'white', padding: '10px', bgcolor: '#0a97d1', marginTop: '20px', width: '280px'
        ,borderRadius:'100px','&:hover ':{bgcolor: '#0a97d1',color:'white'} }}>
        {t('Proceed')}</Button>
      </Stack>
      </Container>
    </div>
  )
}
