import { Box, Stack, useMediaQuery } from '@mui/material';
import React from 'react';
import pic from '../doctor_care_logo.png';

export default function ImagesList({ images }) {
  const matches = useMediaQuery('(min-width:482px)');
  const fallbackImageUrl = pic;
  const newArray = images.slice(1);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <div>
      <Stack gap={2}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: matches ? 'center' : ''
        , alignItems: 'center' }}>
          <img
            src={`${process.env.REACT_APP_API_URL_IMAGE_PRODUCTS}${images[selectedImageIndex]}`}
            onError={(e) => {
              e.target.src = fallbackImageUrl;
            }}
            alt="...loadding"
            style={{ width: matches ? '350px' : '', height: '350px', objectFit: 'fill' }}
          />
        </Box>
        <Stack direction="row" gap={1}>
          {newArray.slice(0, 4).map((n, index) => (
            <img
              key={n}
              src={`${process.env.REACT_APP_API_URL_IMAGE_PRODUCTS}${n}`}
              onError={(e) => {
                e.target.src = fallbackImageUrl;
              }}
              alt="...loadding"
              style={{
                width: matches ? '100px' : '50px',
                height: '100px',
                objectFit: 'fill',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(index + 1)}
            />
          ))}
        </Stack>
      </Stack>
    </div>
  );
}