import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, useMediaQuery } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoadingPage from './LoadingPage'

export default function ProductAddressCards({ addressid, setaddressid }) {
  const [address, setAddress] = React.useState([]);
  const token = localStorage.getItem('token');
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const SwipeableViews = require('react-swipeable-views').default;
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = address.length+1 ;
  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}address/all_address`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAddress(res.data.data.all_address);
        setOpen(false);
      });
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    setaddressid(checked ? id : null);
  };

  const handleCardClick = (id) => {
    const isChecked = addressid === id;
    setaddressid(isChecked ? null : id);
  };

  const matches = useMediaQuery('(min-width:507px)');
  const navigate = useNavigate();

  return (
    <div style={{  position: 'relative',display:'flex',flexWrap:'wrap',justifyContent:'center',
    alignItems:'center' }}>
              {open && <LoadingPage open={open} />}
     {!open && <Box sx={{ maxWidth: matches?'80%':'100%' }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 10,
          pl: 2,
          bgcolor: 'background.default'
        }}
      >
      </Paper>


<SwipeableViews
  index={activeStep}
  onChangeIndex={handleStepChange}
  enableMouseEvents
>
  {address &&
    address.map((a, index) => (
      <div key={a.id}>
        {Math.abs(activeStep - index) <= 2 ? (<>
           <Card
           key={a.id}
           sx={{
             padding: '18px',
             bgcolor: '#0a97d1',
             borderRadius: '10px',
             height: matches?'160px':'200px',
             cursor: 'pointer',
           }}
           onClick={() => handleCardClick(a.id)} 
         >
           <CardContent sx={{ position: 'relative' }}>
           <RadioGroup
   sx={{
     position: 'absolute',
     top: '-15px',
     left: '0px',
   }}
   value={addressid}
   onChange={(e) => handleCheckboxChange(e, a.id)}
 >
   <FormControlLabel
     value={a.id}
     control={
       <Radio
         sx={{
           color: 'white',
           '&.Mui-checked': {
             color: 'white',
           },
         }}
       />
     }
   />
 </RadioGroup>
             <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
               {a.address_title} <br />
             </Typography>
             <Typography gutterBottom sx={{ color: 'white' }}>
               {t('City')} : {a.city}
               <br />
               {t('Area')} : {a.area} <br />
               {t('Street')} : {a.street}
               <br />
               {t('Building')} : {a.building}
             </Typography>
           </CardContent>
         </Card>
      </>
        ) : null}
      </div>
    ))}
     <Card
          sx={{
            padding: '18px',
            bgcolor: '#E1DDE0B3',
            borderRadius: '10px',
            height: matches?'160px':'200px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardContent
            sx={{
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{ color: '#0a97d1', padding: '10px', gap: '5px', fontSize: '15px' }}
              onClick={() => {
                navigate('/AddAddress'); window.location.reload(true)
              }}
            >
              <AddCircleOutlineIcon sx={{ color: '#0a97d1' }} />
              {t('Add new address')}
            </Button>
          </CardContent>
        </Card>
</SwipeableViews>


      <Button sx={{ position:'absolute',
      top:matches?'170px':'220px',left:matches?'20%':'50px',height:'30px',color:'white' }}
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                            <ArrowBackIosIcon />
                        </Button>
                        <Button 
                        sx={{ 
                        position:'absolute',top:matches?'170px':'220px',right:matches?'20%':'50px',color:'white',height:'30px'  }} 
                        size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <ArrowForwardIosIcon />
                        </Button>
    </Box>}
    </div>
  );
}