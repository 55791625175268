import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import HistoryIcon from '@mui/icons-material/History';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import pic from '../doctor_care_logo.png';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../store/authSlice';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

export default function DashBoardNav() {
  const navigate = useNavigate();
  const name = localStorage.getItem('name');
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang')
  const handleAddresses = () => {
    navigate('/DashBoardAddresses');
    window.location.reload(true);
  };

  const handleHistory = () => {
    navigate('/DashBoardService');
    window.location.reload(true);
  };

  const handleSupport = () => {
    navigate('/DashBoardSupport');
    window.location.reload(true);
  };

  const handleProfile = () => {
    navigate('/DashBoardProfile');
    window.location.reload(true);
  };

  const handleProductHistory = () => {
    navigate('/DashBoardProduct');
    window.location.reload(true);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ bgcolor: 'white', color: '#0a97d1' }}>
        <Toolbar sx={{ gap:'2rem' }}>
          <IconButton 
            ref={anchorRef}
            size="xs"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={handleMenuToggle}
          >
            <MenuIcon sx={{ display: { xs: 'flex', sm: 'none' } }}/>
          </IconButton>
          <Typography variant="h6" noWrap component="div" 
          sx={{ flexGrow: 1, fontSize: '20px',marginLeft:lang==='en'?'17rem':'0rem' }}>
            {t('Welcome')} ,{name} !
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
          display: { xs: 'none', sm: 'block'},
        }}
        variant="permanent"
        anchor="left"
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={pic}
            onClick={() => {
              navigate('/');
            }}
            alt="...loading"
            style={{ width: '110px', height: '30px', objectFit: 'contain', padding: '30px', cursor: 'pointer' }}
          />
        </div>
        <List>
          <ListItem disablePadding onClick={handleAddresses}>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#0a97d1' }}>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: '16px' }}>{t('Addresses')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={handleHistory}>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#0a97d1' }}>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: '16px' }}>{t('Services History')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={handleProductHistory}>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#0a97d1' }}>

                <HistoryIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: '16px' }}>{t('Products History')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={handleSupport}>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#0a97d1' }}>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: '16px' }}>{t('Support')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={handleProfile}>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#0a97d1' }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: '16px' }}>{t('Profile')}</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#0a97d1' }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: '16px' }}>{t('Logout')}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Box sx={{ position: 'absolute', top: '93%', left: '15px' }}>
          <Typography sx={{ fontSize: '12px' }}>
            {t('Powered By')} <Link href="https://smartedge.me" sx={{ fontSize: '12px', color: 'black' }}>
              {t('SmartEdge')}
            </Link>
          </Typography>
        </Box>
      </Drawer>
      
      
      <Menu
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 'auto',
            mt: 4,
            backgroundColor: '#f5f5f5',
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
      >
          <MenuItem onClick={()=>{navigate('/')}}>
          <ListItemText>{t('Home Page')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAddresses}>
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText>{t('Addresses')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleHistory}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText>{t('Services History')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleProductHistory}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText>{t('Products History')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSupport}>
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText>{t('Support')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>{t('Profile')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t('Logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}