import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import pic from '../doctor_care_logo.png';
import {Box, Stack} from '@mui/material';
import { useNavigate } from 'react-router';
import axios from 'axios';

export default function ServicesMainPageMiniCards({height,width,imgheight,el,p,setP,s,setS,url}) {
  const navigate = useNavigate()
  const handleClicking =(id,price,total) =>{
    if(s === true){
    axios.get(`${process.env.REACT_APP_API_URL}${id}/show_details_service`).then(res=>{
      navigate('/ServiceDetails', {state : {details :res.data.data  ,price : price ,total:total}})
    setS(false)
    setP(false)})
  }
  if(p === true){
    axios.get(`${process.env.REACT_APP_API_URL}product/${id}`).then(res=>{
      navigate('/ProductsDetails', {state : {details :res.data.data}})
    setP(false)
    setS(false)})
  }
  }
  const fallbackImageUrl = pic;

   return (
    <>
    <Card onClick={()=>{handleClicking(el.id,el.price,el.total)}}
    sx={{height:`${height}`,cursor:'pointer' , 
    bgcolor:'#bcdce5' , width:`${width}`,borderRadius:'10px',padding:'20px 20px 90px 20px'}}>
        <div style={{borderRadius:'10px',boxShadow:'2px 2px 2px 2px rgba(0, 0, 0, 0.2)'}}>
      <CardMedia
        component="img"
        image={`${url}${el.images[0]}`}
        sx={{objectFit:"fill",height:`${imgheight}`,position:'relative'}}
        onError={(e) => {
          e.target.src = fallbackImageUrl
        }}
      />
         <Box sx={{bgcolor:'white',borderRadius:'50px',paddingRight:'10px',paddingLeft:'10px',
        justifyContent:'center',alignItems:'center',display:'flex',flexWrap:'wrap',
        position:'absolute',top:'2rem',right:'2rem',direction:'ltr'}}>
        {el.total  ? <Stack direction='row' gap={1}>
        {el.price !== 'based on period' &&
         <Typography  sx={{color:'#0a97d1',justifyContent:'center',fontSize:'10px',
        alignItems:'center',display:'flex',flexWrap:'wrap', textDecoration: 'line-through 1px'}}>
          {el.price} SAR
        </Typography>}
       <Typography  sx={{color:'#0a97d1',justifyContent:'center',fontSize:'10px',
         alignItems:'center',display:'flex',flexWrap:'wrap'}}>
           {el.total} {el.total !== 'based on period' ? 'SAR' : '' }
         </Typography>
         </Stack> : <>
        {el.price_after_discount ? <Stack direction='row' gap={1}>
        {el.price !== 'based on period' &&
        <Typography  sx={{color:'#0a97d1',justifyContent:'center',fontSize:'10px',
       alignItems:'center',display:'flex',flexWrap:'wrap', textDecoration: 'line-through 1px'}}>
         {el.price} SAR
       </Typography>} 
         <Typography  sx={{color:'#0a97d1',justifyContent:'center',fontSize:'10px',
       alignItems:'center',display:'flex',flexWrap:'wrap'}}>
          {el.price_after_discount} {el.price_after_discount !== 'based on period' ? 'SAR' : '' }
        </Typography> 
       </Stack> :
        <Typography  sx={{color:'#0a97d1',justifyContent:'center',fontSize:'10px',
       alignItems:'center',display:'flex',flexWrap:'wrap'}}>
          {el.price} SAR
        </Typography> }</>}
        </Box>
      </div>
      <CardContent sx={{ justifyContent:'end',alignItems:'end',display:'flex',flexWrap:'wrap' }}>
        <Box sx={{ justifyContent:'end',alignItems:'end',display:'flex',flexWrap:'wrap', }}>
        <Typography sx={{fontWeight:'bold',fontSize:'14px'}}>
            {el.title}
        </Typography>
        </Box>
      </CardContent>
    </Card>
    </>
  );
}