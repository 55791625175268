import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Stack, useMediaQuery } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function AdressesCards({ addressid, setaddressid }) {
  const [address, setAddress] = React.useState([]);
  const token = localStorage.getItem('token');
  const { t } = useTranslation();
  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}address/all_address`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAddress(res.data.data.all_address);
      });
  }, []);

  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;
    setaddressid(checked ? id : null);
  };

  const handleCardClick = (id) => {
    const isChecked = addressid === id;
    setaddressid(isChecked ? null : id);
  };

  const matches = useMediaQuery('(min-width:1000px)');
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <Stack
        direction={matches ? 'row' : 'column'}
        gap={5}
        sx={{
          '.MuiPaper-root': {
            width: '250px',
          },
          display:'flex',flexWrap:'wrap',justifyContent:'start',alignItems:'start'
        }}
      >
        {address.map((a) => (
          <Card
            key={a.id}
            sx={{
              padding: '18px',
              bgcolor: '#0a97d1',
              borderRadius: '10px',
              height: '150px',
              cursor: 'pointer',
            }}
            onClick={() => handleCardClick(a.id)} 
          >
            <CardContent sx={{ position: 'relative' }}>
            <RadioGroup
    sx={{
      position: 'absolute',
      top: '-15px',
      left: '0px',
    }}
    value={addressid}
    onChange={(e) => handleCheckboxChange(e, a.id)}
  >
    <FormControlLabel
      value={a.id}
      control={
        <Radio
          sx={{
            color: 'white',
            '&.Mui-checked': {
              color: 'white',
            },
          }}
        />
      }
    />
  </RadioGroup>
              <Typography sx={{ fontWeight: 'bold', color: 'white' }}>
                {a.address_title} <br />
              </Typography>
              <Typography gutterBottom sx={{ color: 'white' }}>
                {t('City')} : {a.city}
                <br />
                {t('Area')} : {a.area} <br />
                {t('Street')} : {a.street}
                <br />
                {t('Building')} : {a.building}
              </Typography>
            </CardContent>
          </Card>
        ))}
        <Card
          sx={{
            padding: '18px',
            bgcolor: 'white',
            borderRadius: '10px',
            height: '150px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardContent
            sx={{
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{ color: '#0a97d1', padding: '10px', gap: '5px', fontSize: '15px' }}
              onClick={() => {
                navigate('/AddAddress');  window.location.reload(true)
              }}
            >
              <AddCircleOutlineIcon sx={{ color: '#0a97d1' }} />
              {t('Add new address')}
            </Button>
          </CardContent>
        </Card>
      </Stack>
    </div>
  );
}